import clsx from "clsx";
import type { ReactNode } from "react";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { Badge } from "@/components/Badge/Badge.component";
import { BackgroundImage } from "@/components/images/BackgroundImage/BackgroundImage.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { Translation } from "@/components/Translation/Translation.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type LargeFocusArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly trackingId?: string | null;
  readonly renderRelatedArticles?: (articles: ReadonlyArray<ArticleTeaserModel.RelatedArticle>) => ReactNode;
};
export function LargeFocusArticleTeaser({
  className,
  article,
  trackingId,
  hasImagePriority,
  renderRelatedArticles
}: LargeFocusArticleTeaserProps) {
  const hasImage = article?.content.supportingImage !== null;
  if (!article) {
    return null;
  }
  const {
    targetUrl,
    id,
    supportingImage,
    headline,
    relatedArticles
  } = article.content;
  const {
    requiresSubscription
  } = article;
  return <article className={className} data-sentry-component="LargeFocusArticleTeaser" data-sentry-source-file="LargeFocusArticleTeaser.component.tsx">
      <SnowplowLink className="duration-200 ease-in-out hover:brightness-95" href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
      articleId: id,
      trackingId: trackingId ?? null,
      url: targetUrl
    }} data-sentry-element="SnowplowLink" data-sentry-source-file="LargeFocusArticleTeaser.component.tsx">
        <div className="relative bg-whisper text-white">
          {hasImage ? <BackgroundImage hasImagePriority={hasImagePriority} sourceSetSize={`(max-width: ${tailwindScreenSize.md}) 120vw, 964px`} supportingImage={supportingImage} /> : null}
          <div className={clsx(hasImage && "min-h-[30rem]")}>
            <div className={clsx(hasImage ? "absolute bottom-0 flex w-full flex-col gap-y-1 bg-gradient-to-b from-transparent to-nero pt-4" : "bg-nero", "p-1.5 lg:pb-3")}>
              <Badge className={clsx(hasImage ? "mb-1" : "mb-2")} data-sentry-element="Badge" data-sentry-source-file="LargeFocusArticleTeaser.component.tsx">
                <Translation da="Fokus" de="Ausgewählt" en="Featured" no="Fokus" sv="I fokus" data-sentry-element="Translation" data-sentry-source-file="LargeFocusArticleTeaser.component.tsx" />
              </Badge>

              <ArticleTeaserHeading className="text-4xl font-bold hover:underline lg:text-5xl" title={headline.html} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="LargeFocusArticleTeaser.component.tsx" />
            </div>
          </div>
        </div>
      </SnowplowLink>

      <div className="bg-nero p-1.5 pt-0 text-white empty:hidden lg:pb-3">
        {relatedArticles.length > 0 ? renderRelatedArticles?.(relatedArticles) : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className={clsx("text-white", relatedArticles.length > 0 && "mt-2 lg:mt-3")} /> : null}
      </div>
    </article>;
}